import './OperatorLogo.scss';

import chinamobile from './logos/chinamobile.png';
import demotel from './logos/demotel.png';
import digicel from './logos/digicel.svg';
import ee from './logos/ee.svg';
import enetworks from './logos/enetworks.svg';
import eSIM from './logos/eSIM.svg';
import esimchoice from './logos/esimchoice.svg';
import esimrise from './logos/esimrise.png';
import flexiroam from './logos/flexiroam.png';
import goHub from './logos/GoHub.png';
import kddi from './logos/KDDI.png';
import lagardere from './logos/lagardere.svg';
import lebara from './logos/lebara.png';
import lyca from './logos/lyca.svg';
import movistar from './logos/movistar.svg';
import o2 from './logos/o2.svg';
import orange from './logos/orange.svg';
import smartroam from './logos/smartroam.svg';
import three from './logos/three.svg';
import truphone from './logos/truphone.svg';
import ubigi from './logos/ubigi.png';
import vodafone from './logos/vodafone.svg';
import wisesim from './logos/wisesim.png';

export const logos: Record<string, string | undefined> = {
  chinamobile,
  demotel,
  digicel,
  ee,
  enetworks,
  eSIM,
  esimchoice,
  esimrise,
  flexiroam,
  lagardere,
  lebara,
  lyca,
  movistar,
  o2,
  orange,
  smartroam,
  three,
  truphone,
  ubigi,
  vodafone,
  wisesim,
  kddi,
  goHub,
};

export const validOperatorLogoNames = Object.keys(logos);

interface Props {
  name: string;
}

const className = 'c-OperatorLogo';

export const OperatorLogo = ({ name }: Props) => {
  const logo = logos[name] ?? eSIM;

  return (
    <div className={className}>
      <img src={logo} alt="" className={`${className}__image`} />
    </div>
  );
};
